<!--
 * @Description:计费规则详情 billRuleDetail
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-11 19:38:45
 * @LastEditors: zhoucheng
-->
<!--  -->
<template>
  <div class='billRuleDetail'>
    <van-nav-bar title="收费规则详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-row class="top">渝北停车场计费规则</van-row>
      <van-row class="tag">
        <van-tag color="#19A9FC"
                 type="middle"
                 round>{{BillingRuleDetail.billingRuleTypeName}}</van-tag>
      </van-row>
      <van-row class="divider"></van-row>
      <van-row class="middle textOne">计费规则细则</van-row>
      <van-row class="textThree"
               style="margin-top:12px;margin-bottom: 10px;">1.白天({{billingRuleDetailList.dayBeginTime}}-{{billingRuleDetailList.dayEndTime}})</van-row>
      <van-row class="cell-title textFour">
        <van-col span="6">计费金额</van-col>
        <van-col span="6">免费时长</van-col>
        <van-col span="6">重新计费时长</van-col>
        <van-col span="6">封顶金额</van-col>
      </van-row>
      <van-row class="cell-value textTwo">
        <van-col span="6">{{billingRuleDetailList.dayFreeDuration}}元</van-col>
        <van-col span="6">{{billingRuleDetailList.dayFreeDuration}}分钟</van-col>
        <van-col span="6">{{billingRuleDetailList.dayMinBillingDuration}}分钟</van-col>
        <van-col span="6">{{billingRuleDetailList.nightFreeDuration}}元</van-col>
      </van-row>
      <van-row class="textThree"
               style="margin-top:12px;margin-bottom: 10px;">2.夜间({{billingRuleDetailList.nightBeginTime}}-{{billingRuleDetailList.nightEndTime}})</van-row>
      <van-row class="cell-title textFour">
        <van-col span="6">计费金额</van-col>
        <van-col span="6">免费时长</van-col>
        <van-col span="6">重新计费时长</van-col>
        <van-col span="6">封顶金额</van-col>
      </van-row>
      <van-row class="cell-value textTwo">
        <van-col span="6">{{billingRuleDetailList.nightBillingPrice}}元</van-col>
        <van-col span="6">{{billingRuleDetailList.nightFreeDuration}}分钟</van-col>
        <van-col span="6">{{billingRuleDetailList.nightMinBillingDuration}}分钟</van-col>
        <van-col span="6">{{billingRuleDetailList.nightCeilingAmount}}元</van-col>
      </van-row>
      <van-row class="middle textOne">计费规则描述</van-row>
      <van-row class="down textThree">
        一、重要商圈内路段占道停车收费标准及时段 <br>
        1.白天（8:00-22:00）时段每车每半小时2.5元； <br>
        2.夜间（22:00-次日8:00）时段每车每半小时2元，且每车每次最高不超过10元。<br>
        二、重要商圈外路段占道停车收费标准及时段<br>
        1.白天（8:00-20:00）时段每车每半小时1元；<br>
        2.夜间（20:00-次日8:00）时段每车每半小时1元，且每车每次最高不超过5元。<br>
        实施时间：<br>
        新标准自2014年9月1日起实施，之前占道停车收费标准同时作废。</van-row>
    </van-row>

  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      BillingRuleDetailInfo: [], // 查询计费规则参数
      BillingRuleDetail: [], // 计费规则详情
      billingRuleDetailList: []// 计费规则细则
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.BillingRuleDetail = this.$route.query
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getBillingRule()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取计费规则详情
    getBillingRule () {
      this.$billRuleDetail.queryBillingRuleById(this.BillingRuleDetail).then(res => {
        this.BillingRuleDetail = res.resultEntity
        this.billingRuleDetailList = res.resultEntity.billingRuleDetailList[0]
        if (Number(this.billingRuleDetailList.nightEndTime) / 60 > 24) {
          this.billingRuleDetailList.nightEndTime = Number(this.billingRuleDetailList.nightEndTime) / 60 - 24 + ':00'
        } else {
          this.billingRuleDetailList.nightEndTime = Number(this.billingRuleDetailList.nightEndTime) / 60 + ':00'
        }
        this.billingRuleDetailList.dayBeginTime = Number(this.billingRuleDetailList.dayBeginTime) / 60 + ':00'
        this.billingRuleDetailList.dayEndTime = Number(this.billingRuleDetailList.dayEndTime) / 60 + ':00'
        this.billingRuleDetailList.nightBeginTime = Number(this.billingRuleDetailList.nightBeginTime) / 60 + ':00'
        this.billingRuleDetailList.dayBillingPrice = Number(Number(this.billingRuleDetailList.dayBillingPrice) / 100).toFixed(2)
        this.billingRuleDetailList.nightBillingPrice = Number(Number(this.billingRuleDetailList.nightBillingPrice) / 100).toFixed(2)
        this.billingRuleDetailList.dayCeilingAmount = Number(Number(this.billingRuleDetailList.dayCeilingAmount) / 100).toFixed(2)
        this.billingRuleDetailList.nightCeilingAmount = Number(Number(this.billingRuleDetailList.nightCeilingAmount) / 100).toFixed(2)
      })
    },
    // 顶部返回按钮
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.billRuleDetail {
  // height: 100%;
  width: 100%;
  background-color: #fff;
  .main {
    margin: 12px 3.2% 0px 3.07%;
    height: 100%;
    width: 93.73%;
    .top {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .tag {
      margin: 8px 0 8px 0;
    }
    .divider {
      width: 100%;
      border-top: 1px #cfcfcf dashed;
    }
    .middle {
      margin-top: 15px;
    }
    .cell-title {
      text-align: center;
      border-top: 1px #ebebeb solid;
      height: 30px;
      line-height: 30px;
    }
    .cell-value {
      text-align: center;
      border-top: 1px #ebebeb solid;
      border-bottom: 1px #ebebeb solid;
      height: 38px;
      line-height: 38px;
    }
    .down {
      margin-top: 10px;
      line-height: 23px;
    }
    .textOne {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .textTwo {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .textThree {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .textFour {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>
